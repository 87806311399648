import React from 'react';
import { LocaleProvider, Spin, Carousel, Anchor } from 'antd';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import visitorImg from './img/visitor.png';
import leaveImg from './img/leave.png';
import describeImg from './img/describe.png';
import qrcodeImg from './img/xundaanqrcode.jpg';

import './App.css';
const { Link } = Anchor;
function App() {
  return (
    <LocaleProvider locale={zhCN}>

      <div>
        <div className='home'>
          {/* 头部nav */}
          <div className='home_tit'>
            <div className='head'>
              <img className='tit' src={process.env.PUBLIC_URL + '/home/logo.png'} />
              <div className='nav'>
                <a href="/admin/#/user/login" className='btn'>登录入口</a>
                <div className='link'>
                  <Anchor style={{ background: "transparent" }}>
                    <Link className='link_item' href="#about" title="关于我们" />
                    <Link className='link_item' href="#features" title="技术特性" />
                    <Link className='link_item' href="#app" title="APP下载" />
                    <Link className='link_item' href="#banner" title="产品介绍" />
                    <Link className='link_item' href="#head" title="首页" />
                  </Anchor>
                </div>
              </div>
            </div>
          </div>
          <div id="head" className='home_head'>
            <div className='cicle1'></div>
            <div className='cicle2'></div>
            <div className='cicle3'></div>
            <div className='head_content'>
              <img src={process.env.PUBLIC_URL + '/home/img_2.png'} />
              <div className='content'>
                <div className='title'>讯达安<span>平安校园智慧管理系统</span></div>
                <p>XunDaAn Intelligence Management System</p>
                <div className='btn'>电子考勤</div>
                <div className='btn'>电子点名</div>
                <div className='btn'>一键通知家长</div>
                <div className='btn'>讯达安访校</div>
                <div className='btn'>学生请假</div>
              </div>
            </div>
          </div>
          {/* 产品介绍 */}
          <div id="banner">
            <Carousel autoplay>
              <div className='home_banner' >
                <h3 className='title' >产品介绍</h3>
                <div className='con' >
                  <div className='content' >
                    <h4>电子考勤</h4>
                    <p>学生出入校考勤，家长实时接收短信提醒，家校双方共同掌握学生动向，防范学生到校前、离校后发生意外事故，加强校园安全管理。</p>
                  </div>
                  <img src={process.env.PUBLIC_URL + '/home/img_3.png'} />
                </div>
              </div>
              <div className='home_banner' >
                <h3 className='title' >产品介绍</h3>
                <div className='con' >
                  <div className='content' >
                    <h4>电子点名</h4>
                    <p>一分钟完成班级点名，快速确认未到校学生。教师通过电子点名系统点名，学校即时查看学生缺课、逃课信息。考勤信息同步至教务管理部门，有效防止学生逃学逃课。</p>
                  </div>
                  <img src={process.env.PUBLIC_URL + '/home/img_4.png'} />
                </div>
              </div>
              <div className='home_banner' >
                <h3 className='title' >产品介绍</h3>
                <div className='con' >
                  <div className='content' >
                    <h4>一键通知家长</h4>
                    <p>学生未到校，学校一键通知家长，明晰家校责任。学校及时发送安全提醒，变被动应对为主动防范，为因意外事故产生的家校纠纷提供可靠依据。</p>
                  </div>
                  <img src={process.env.PUBLIC_URL + '/home/img_5.png'} />
                </div>
              </div>
              <div className='home_banner' >
                <h3 className='title' >产品介绍</h3>
                <div className='con' >
                  <div className='content' >
                    <h4>讯达安访校</h4>
                    <p>访客身份精准识别，杜绝可疑人员入校，简化流程提高效率，访客信息快速登记，访客记录永久保存。</p>
                  </div>
                  <img src={visitorImg} />
                </div>
              </div>
              <div className='home_banner' >
                <h3 className='title' >产品介绍</h3>
                <div className='con' >
                  <div className='content' >
                    <h4>学生请假</h4>
                    <p>家长通过讯达安公众号为孩子发起请假，经老师同意后，学生可直接使用学生卡刷闸机离校。同时，请假数据还将实时同步到点名系统。老师点名时，系统自动可分辨已请假学生。</p>
                  </div>
                  <img src={leaveImg} />
                </div>
              </div>
            </Carousel>
          </div>
          {/* app下载 */}
          <div id="app" className='home_app' >
            <h3 className='title' >"讯达安" 公众号</h3>
            <div className='con' style={{ marginTop: '90px' }}>
              <img className='img' src={describeImg} />
              <div className='content' >
                <h4 style={{ fontSize: '14px', marginLeft: '8px' }}>扫 码 关 注 讯 达 安 公 众 号</h4>
                {/* <div className='btns' >
                  <div className='btn' >
                    <img src={process.env.PUBLIC_URL + '/home/ad_icon.png'}  />
                    <span>android</span>
                  </div>
                  <div className='btn'  style={{marginTop:'30px'}}>
                    <img src={process.env.PUBLIC_URL + '/home/ip_icon.png'}  />
                    <span>iphone</span>
                  </div>
                </div> */}
                <div className='qrcode' >
                  <img src={qrcodeImg} />
                </div>
              </div>
            </div>
          </div>
          {/* 技术特性 */}
          <div id="features" className='home_features' >
            <h3 className='title' >技术特性</h3>
            <div className='content' >
              <div className='item' >
                <img src={process.env.PUBLIC_URL + '/home/img_8.png'} />
                <h4>成熟可靠的运行环境</h4>
                <p>基于阿里云ECS服务器构建，提供持续稳定的业务应用能力和强大的扩展能力</p>
              </div>
              <div className='item' >
                <img src={process.env.PUBLIC_URL + '/home/img_9.png'} />
                <h4>集群化管理模式</h4>
                <p>引入“站群”管理模式，服务端实施智能化、自动化的部署和维护，用户只需专注业务应用</p>
              </div>
              <div className='item' >
                <img src={process.env.PUBLIC_URL + '/home/img_10.png'} />
                <h4>基于角色的灵活部署</h4>
                <p>每个角色的都对应的独立功能模块，满足学校个性化的应用需求</p>
              </div>
            </div>
            <div className='content' >
              <div className='item' >
                <img src={process.env.PUBLIC_URL + '/home/img_11.png'} />
                <h4>多重安全机制</h4>
                <p>软硬兼施，为网络、服务器、平台软件建立全方位的入侵防护体系，确保数据存储安全可靠</p>
              </div>
              <div className='item' >
                <img src={process.env.PUBLIC_URL + '/home/img_12.png'} />
                <h4>跨平台适应</h4>
                <p>桌面电脑、移动设备全面支持，无需安装电脑客户端即可使用。</p>
              </div>
              <div className='item' >
                <img src={process.env.PUBLIC_URL + '/home/img_13.png'} />
                <h4>轻快简洁的界面设计</h4>
                <p>至轻、至快，简单的配色和清晰的布局，清新雅致的界面风格为您提供最出色的人机交互体验</p>
              </div>
            </div>
          </div>
          {/* 数据统计 */}
          <div className='count' >
            <div className='list' >
              <div className='item' >
                <div>300<span style={{ right: '6px' }}>+</span></div>
                <p>个优秀员工</p>
              </div>
              <div className='item' >
                <div>2400<span>+</span></div>
                <p>所服务学校</p>
              </div>
              <div className='item' >
                <div>6000<span>+</span></div>
                <p>个成功案例</p>
              </div>
              <div className='item' >
                <div>3500000<span style={{ right: '-10px' }}>+</span></div>
                <p>个学生家庭</p>
              </div>
            </div>
          </div>
          {/* 关于我们 */}
          <div id="about" className='about' >
            <h3 className='title' >关于我们</h3>
            <div className='content' >
              <div className='item' >
                <img src={process.env.PUBLIC_URL + '/home/img_14.jpg'} />
                <h4>公司文化</h4>
                <div className='detail' >
                  <h5>公司文化</h5>
                  <p>拼搏 执行 创新 荣誉
                    诚信第一  责任至上
                    自主创新  虚心求学
                    改变教育  服务人民
                  </p>
                </div>
              </div>
              <div className='item' >
                <img src={process.env.PUBLIC_URL + '/home/img_15.jpg'} />
                <h4>荣誉资质</h4>
                <div className='detail' style={{ padding: '76px 62px 0 80px' }}>
                  <h5>荣誉资质</h5>
                  <p>河南省AAA级信用企业
                    国家双软认证企业
                  </p>
                </div>
              </div>
            </div>
            <div className='content' >
              <div className='item' >
                <img src={process.env.PUBLIC_URL + '/home/img_16.jpg'} />
                <h4>业界交流</h4>
                <div className='detail' >
                  <h5>业界交流</h5>
                  <p>
                    参加漯河市教育信息化交流会
                  </p>
                  <p>承办省教育厅信息化教学与应用展览会</p>
                </div>
              </div>
              <div className='item' >
                <img src={process.env.PUBLIC_URL + '/home/img_17.jpg'} />
                <h4>关于我们</h4>
                <div className='detail' >
                  <h5>关于我们</h5>
                  <p>
                    提供教育信息化一站式解决方案
                  </p>
                </div>
              </div>
            </div>
            <div className='footer'>
            版权所有：
            <a className='link'>讯达安（北京）教育科技有限公司</a>
            <a className='link' target='_blank' href="http://www.beian.miit.gov.cn/">  京ICP备20031607号</a> ©2020
	        </div>
          </div>
          {/* 底部banner */}
          {/* <div className='bottom' >
            <div className='con' >
              <div className='left' >
                <p>讯达安-平安校园智慧管理系统</p>
              </div>
              <div className='right' >
                <div className='img_box' >
                  <img src={process.env.PUBLIC_URL + '/home/img_7.png'}  />
                </div>
                <span>APP下载</span>
              </div>
            </div>
          </div> */}
          

        </div>
      </div>
    </LocaleProvider>
  );
}

export default App;
